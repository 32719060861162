<template>
    <div class="loader-container" :style="style">
        <img class="logo" lazy-src="../assets/logo_icon.svg" max-height="150" max-width="150" src="../assets/logo_icon.svg" />
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        height: {
            default: "200px"
        }
    },
    computed: {
      style () {
        return 'height: ' + this.height;
      }
    },
};
</script>

<style scoped>
    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @keyframes pulse {
	0% {
		transform: scale(0.9);
        rotate: 0deg;
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.90);
        rotate: 360deg;
	}
}
    .logo {
        /* make the element animate pulse */
        animation: pulse 2s infinite;
        height: 100px;
    }
</style>