<script>

export default {
	props: ["event", "active"],
	data() {
		return {
			options: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}
		}
	},
	computed: {
		cssProps() {
			return {
				'--bg-image': (this.event.image)
			}
		},
		getLanguage() {
			return this.$store.state.language;
		},
		getContent() {
			return this.$store.state;
		},
	}
}

</script>

<template>
	<div class="eventCard">
		<div class="eventImage" :class="{blackwhite: !active}"><img :src="event.image" alt="event image"></div>
		<div class="eventContent">
			<div>
				<h4 class="eventTitle">{{ event.name }}</h4>
				<div class="eventIconInfo">
					<img src="../assets/calender.svg" alt="calendar icon">
					<p>{{
							new Date(event.startDate).toLocaleDateString(
								(getContent.lang[getLanguage]), options) + ", " + event.startTime
						}} – {{
							(event.startDate === event.endDate) ?
									event.endTime :
									new Date(event.endDate).toLocaleDateString((getContent.lang[getLanguage]), options) + ", " + event.endTime
						}}</p>
				</div>
				<div class="eventIconInfo">
					<img src="../assets/location.svg" alt="calendar icon">
					<p>{{ event.location }}</p>
				</div>
				<p v-html="event.description" class="eventDescription"></p>
			</div>
			<a v-if="active" :href="event.link" target="_blank" class="eventButton">{{ event.linkText }}</a>
		</div>
	</div>
</template>


<style lang="scss">
.eventCard {
	display: flex;
	flex-direction: column;
	width: auto;
	height: 100%;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 4px 12px -10px #000000;
	transition: all 0.3s ease-in-out;

	&:hover {
		box-shadow: 0 4px 20px -10px #000000;
	}
}

.eventCard.center {
	grid-area: center;
}

.eventImage {
	width: 100%;
	height: 160px;
	border-radius: 10px 10px 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;

	img {
		width: 100%;
		object-fit: cover;
	}
}

.blackwhite {
	img {
		filter: grayscale(100%);
	}
}

.eventContent {
	padding: 40px 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	flex: 1;

	@media only screen and (max-width: 768px) {
		padding: 30px 30px;
	}
}

.eventDescription {
	margin: 20px 0 20px 0;
	color: $light-text;
}

.eventButton {
	display: inline-block;
	background-color: $lumos-pink;
	color: white;
	border-radius: 4px;
	border: 1px solid;
	padding: 8px 20px;
	margin-top: auto;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	justify-self: end;
	font-weight: 500;

	&:hover {
		background-color: white;
		border-color: $lumos-pink;
		color: $lumos-pink;
		text-decoration: none;
	}
}

p {
	margin-bottom: 0;
}

.eventIconInfo {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	img {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	p {
		font-weight: 500;
	}
}

.eventTitle {
	font-weight: 600;
	margin-bottom: 20px;
}
</style>